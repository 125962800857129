@import url(https://fonts.googleapis.com/css?family=Oswald);
/* @import url(https://fonts.googleapis.com/css?family=Dosis:400,300,500,600,700,800); */
/* @import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,300,600,700,900); */
/* @import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css); */
  
body {
  margin: 0;
  padding: 0;
  font-family: 'Oswald', sans-serif;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;  /* Chrome all / Safari all */     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */     
}

